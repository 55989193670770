/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  PieChart as PieChartIcon,
  Home as HomeIcon,
  Trello as TrelloIcon,
  Map as MapIcon,
  MapPin as MapPinIcon,
  Phone as PhoneIcon,
  ShoppingBag as ShoppingBagIcon,
  Chrome as ChromeIcon,
  Monitor as MonitorIcon,
  File as FileIcon,
  Code as CodeIcon,
  FileText as FileTextIcon,
  FilePlus as FilePlusIcon,
  FileMinus as FileMinusIcon,
  Search as SearchIcon,
  StopCircle as StopCircleIcon,
  BarChart2 as BarChart2Icon,
  Grid as GridIcon
} from 'react-feather';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';

const sections = [
  {
    items: [
      {
        title: 'API Dashboard',
        icon: PieChartIcon,
        href: '/api/dashboard'
      }
    ]
  },
  {
    subheader: 'AI QRCode API',
    items: [
      {
        title: 'Generate AI QRCode',
        icon: GridIcon,
        href: '/api/ai-qrcode-api'
      },
      {
        title: 'View AI QRCode',
        icon: BarChart2Icon,
        href: '/api/ai-qrcode-api'
      },
    ]
  },
  {
    subheader: 'Verification API',
    items: [
      {
        title: 'Validate Email',
        icon: AlternateEmailIcon,
        href: '/api/verification/validate-email'
      },
      {
        title: 'Validate Phone',
        icon: PhoneIcon,
        href: '/api/verification/validate-phone'
      },
      {
        title: 'Validate VAT',
        icon: ShoppingBagIcon,
        href: '/api/verification/validate-vat'
      },
      {
        title: 'User Agent',
        icon: ChromeIcon,
        href: '/api/verification/validate-useragent'
      },
    ]
  },
  {
    subheader: 'UK PostCode API',
    items: [
      {
        title: 'PostCode Lookup',
        icon: HomeIcon,
        href: '/api/postcode/find-by-postcode'
      },
        {
          title: 'Address Lookup',
          icon: MapIcon,
          href: '/api/postcode/find-by-addresses'
        },
     {
          title: 'UDPRN Lookup',
          icon: TrelloIcon,
          href: '/api/postcode/find-by-udprn'
        },
     {
          title: 'Address Autosuggest',
          icon: MapPinIcon,
          href: '/api/postcode/address-autosuggest'
        },
    ]
  },
    
    {
      subheader: 'Web Conversion API',
      items: [
        {
          title: 'ScreenShot API',
          icon: MonitorIcon,
          href: '/api/conversion/web-conversion'
        },
        {
          title: 'Web To PDF API',
          icon: FileIcon,
          href: '/api/conversion/web-conversion'
        },
        {
          title: 'Web Scrape API',
          icon: CodeIcon,
          href: '/api/conversion/web-conversion'
        },
        {
          title: 'Web To Text API',
          icon: FileTextIcon,
          href: '/api/conversion/web-conversion'
        }
      ]
    },
    {
      subheader: 'Data Conversion API',
      items: [
        {
          title: 'JSON TO XML',
          icon: FilePlusIcon,
          href: '/api/conversion/data-conversion'
        },
        {
          title: 'XML TO JSON',
          icon: FileMinusIcon,
          href: '/api/conversion/data-conversion'
        },
        {
          title: 'JSON TO CSV',
          icon: FilePlusIcon,
          href: '/api/conversion/data-conversion'
        },
        {
          title: 'CSV TO JSON',
          icon: FileMinusIcon,
          href: '/api/conversion/data-conversion'
        },
        {
          title: 'CSV TO XML',
          icon: FilePlusIcon,
          href: '/api/conversion/data-conversion'
        }
      ]
    },
    {
      subheader: 'India Pincode API',
      items: [
        {
          title: 'Find By Pincode',
          icon: SearchIcon,
          href: '/api/pincode/find-by-pincode'
        },
        {
          title: 'Find By Places',
          icon: StopCircleIcon,
          href: '/api/pincode/find-by-pincode'
        }
      ]
    },
    {
      subheader: 'QRCODE API',
      items: [
        {
          title: 'Generate BarCode',
          icon: BarChart2Icon,
          href: '/api/barcode-api'
        },
        {
          title: 'Generate QRCode',
          icon: GridIcon,
          href: '/api/barcode-api'
        },
      ]
    },
  {
    subheader: 'Payment',
    items: [       
        // {
        //   title: 'Subscription Details',
        //   icon: GridIcon,
        //   href: 'https://billing.stripe.com/p/login/14kaFp2j48IF3M47ss'
        // },
        {
          title: 'PAYG Billing History',
          icon:  ReceiptIcon,
          href:  '/api/payment/billing'
        },
    ]
  }

];


function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"

          >
            <RouterLink to="/app/account" style={{textDecoration:'none'}}>
              <Avatar
                alt="User"
                className={classes.avatar}
                style={{backgroundColor:'#3949ab'}}
              >
                {user.avatar}
              </Avatar>
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
              style={{fontSize : user.email.length > 25 ? 11 : 16 }}
            >
              {user.email}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              Your tier:
              {' '}
              <Link
                component={RouterLink}
                to="/api/pricing-plans"
              >
                {user.tier}
              </Link>
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section, index) => (
            <List
              key={section.subheader+ '_'+ index}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to={{ pathname: "https://docs.apitier.com"}}
              target = "_blank"
            >
              Check our docs
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
